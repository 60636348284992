import React, { useState, useEffect } from "react";
import "./App.css";
import { sign, checkConnection, connect } from './Web3Service.ts';
import { Link } from "react-router-dom";

function App() {
  const [hash, setHash] = useState("");
  const [txHash, setTxHash] = useState("");
  const [result, setResult] = useState("");
  const [badResult, setBadResult] = useState(null);
  const [connected, setConnected] = useState(false);

  useEffect(() => {
    (async () => {
      const connection = await checkConnection();
      setConnected(connection);
    })();
  }, []);

  const connectAction = async () => {
    await connect();
    const connection = await checkConnection();
    setConnected(connection);
  }

  const clearResults = () => {
    setHash("");
    setTxHash("");
    setResult("");
    setBadResult(null);
  }

  const uploadCheckAction = () => {
    clearResults();

    const fileInput = document.getElementById("file");
    if (fileInput && fileInput.files.length > 0) {
      const file = fileInput.files[0];
      const formData = new FormData();
      formData.append("file", file);
      fetch(`${process.env.REACT_APP_SERVER_URL}/verify`, {
        method: "POST",
        body: formData,
      })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then((result) => {
        setResult(result);
        
        console.log("Success:", result);
      })
      .catch((error) => {
        setBadResult(true);
        console.error("Error:", error);
      });
    } else {
      console.error("No file selected");
    }
  }

  const uploadAction = async () => {
    const fileInput = document.getElementById("file");
    if (fileInput && fileInput.files.length > 0) {
      const file = fileInput.files[0];
      const formData = new FormData();
  
      formData.append("file", file);
      try {
        const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/serialize`, {
          method: "POST",
          body: formData,
        });
  
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
  
        const result = await response.json();
        // setHash(result.fileHash);
        console.log("Success:", result);
  
        await connectWallet(result.fileHash);
      } catch (error) {
        console.error("Error:", error);
      }
    } else {
      console.error("No file selected");
    }
  };
  
  const connectWallet = async (fileHash) => {
    try {
      if (fileHash === "") {
        alert("Please upload a file first");
        return;
      }
  
      const txHash = await sign(fileHash);
      clearResults();
      setTxHash(txHash);
    } catch (error) {
      alert(error.message);
    }
  };

  return (
    <>
      <div className="cover-container d-flex w-100 h-100 p-3 mx-auto flex-column">
        <header className="mb-auto">
          <div>
            <h3 className="float-md-start mb-0" style={{color: "#a6e9d5"}} >CryptoSign</h3>
            <nav className="nav nav-masthead justify-content-center float-md-end">
              <Link to={`/`} className={"nav-link fw-bold py-1 px-0 active"}>Home</Link>
              <Link to={`about`} className={"nav-link fw-bold py-1 px-0"}>About</Link>
              <Link to={`features`} className={"nav-link fw-bold py-1 px-0"}>Features</Link>
            </nav>
          </div>
        </header>


        <main className="px-3">
          <h1><span style={{color: "#a6e9d5"}}>Sign</span> and <span style={{color: "#a6e9d5"}}>protect</span> your files</h1>
          <p className="lead mb-5">AI and Blockchain are used to authenticate the integrity of your agreements in digital documents and files.</p>

          <p className="lead px3 mb-5">
            First step, connect your wallet 

            { connected && (
              <a href="#" className="btn btn-success fw-bold m-2 disabled">
                <img src="metamask.svg" alt="Metamask" width={32} />
                Connected
              </a>
            ) }

            { !connected && (
              <a href="#" className="btn btn-success fw-bold m-2" onClick={connectAction}>
                <img src="metamask.svg" alt="Metamask" width={32} />
                Connect Metamask
              </a>
            ) }
          </p>
          
          <div className="mb-4">
            <p className="lead mb-4">Second step, choice a file to upload</p>
            <input type="file" className="form-control" id="file" />
          </div>
          <input
            type="button"
            className="btn btn-light fw-bold border-white bg-white btn-sm"
            value="Sign & Store"
            onClick={uploadAction}
          /> &nbsp;
          <input
            type="button"
            className="btn btn-warning btn-sm"
            value="Check integrity"
            onClick={uploadCheckAction}
          />
          <p className="lead">
            {hash && (
              <p className="lead">
                File hash: <strong>{hash}</strong>
              </p>
            )}
          </p>
        </main>

        {txHash && (
          <div className="d-flex flex-column flex-md-row p-4 gap-4 py-md-5 align-items-center justify-content-center">
            <div className="list-group">
              <a href="#" className="list-group-item list-group-item-action d-flex gap-3 py-3" aria-current="true">
                <div className="d-flex gap-2 w-100 justify-content-between">
                  <div>
                    <h6 className="mb-0">Transaction hash</h6>
                    <p className="mb-0 opacity-75">{txHash}</p>
                  </div>
                </div>
              </a>
            </div>
          </div>
        )}

        <div>
          {result && (
            <div className="alert alert-success" role="alert">
              <h4>The file is intact!</h4>
              <hr />
              {result.timestamp && (
                <div className="text-start">
                  <p>File details</p>
                  <p className="small">File Address: {result.message}<br /> Date: {result.timestamp.toLocaleString()}</p>
                </div>
              )}
            </div>
          )}

          {badResult && (
            <div className="alert alert-warning" role="alert">
              File not found! Reasons: The file is different or it is not intact.
            </div>
          )}
        </div>
        

        <footer className="mt-auto text-white-50">
          <p>
            Built by{" "}
            <a href="https://p2ptech.dev/" className="text-white">
              P2PTechnology
            </a>
          </p>
        </footer>
      </div>
    </>
  );
}

export default App;
