import Web3 from "web3";
import ABI from "./abi.json";

export async function sign(fileHash) {
  if (typeof (window as any).ethereum === "undefined") {
    throw new Error("No MetaMask found!");
  }

  const web3 = new Web3((window as any).ethereum);
  const accounts = await web3.eth.requestAccounts();

  if (!accounts || accounts.length === 0) {
    throw new Error("No accounts found!");
  }

  const contractAddress = process.env.REACT_APP_CONTRACT_ADDRESS;
  const contract = new web3.eth.Contract(ABI, contractAddress, {
    from: accounts[0],
  });

  const tx = await contract.methods.sign(fileHash).send({ from: accounts[0] });
  return tx.transactionHash;
}

export async function connect() {
  if (typeof (window as any).ethereum === "undefined") {
    throw new Error("No MetaMask found!");
  }

  await (window as any).ethereum.request({ method: "eth_requestAccounts" });
}

export async function checkConnection() {
  if (typeof (window as any).ethereum === "undefined") {
    return false;
  }

  const accounts = await (window as any).ethereum.request({
    method: "eth_accounts",
  });

  return accounts && accounts.length > 0;
}
