import React from "react";
import "./App.css";
import { Link } from "react-router-dom";


function Feature() {

  return (
    <>
      <div className="cover-container d-flex w-100 h-100 p-3 mx-auto flex-column">
        <header className="mb-auto">
          <div>
            <h3 className="float-md-start mb-0" style={{color: "#a6e9d5"}}>CryptoSign</h3>
            <nav className="nav nav-masthead justify-content-center float-md-end">
              <Link to={`/`} className={"nav-link fw-bold py-1 px-0"}>Home</Link>
              <Link to={`/about`} className={"nav-link fw-bold py-1 px-0"}>About</Link>
              <Link to={`/features`} className={"nav-link fw-bold py-1 px-0 active"}>Features</Link>
            </nav>
          </div>
        </header>


        <main className="text-start">
          <h4 className="mb-5 text-center" style={{color: "#1aa179"}}>How we change the game</h4>
          
          <div className="container marketing">

              <div className="row g-3">
                <div className="col border border-light-subtle rounded-3 p-4 m-1">
                  <h4 className="fw-normal text-center">Tokenize everything</h4>
                  <p>Tokenize anything: contracts, agreements, notices or copyright documents.</p>
                </div>
                <div className="col border border-light-subtle rounded-3 p-4 m-1">
                  <h4 className="fw-normal text-center">Timestamping</h4>
                  <p>We allows the use of an electronic timestamp to supply a date in certified documents.</p>
                </div>
              </div>

               <div className="row gx-2">
                <div className="col border border-light-subtle rounded-3 p-4 m-1">
                  <h4 className="fw-normal text-center">Information Integrity</h4>
                  <p>Document information is extracted and used to generate the unique token on an Ethereum Blockchain creating the information integrity guarantee.</p>
                </div>

                <div className="col border border-light-subtle rounded-3 p-4 m-1">
                  <h4 className="fw-normal text-center">APIs Integration</h4>
                  <p>APIs allow you to create, upload and upload information automatically on the blockchain, maintaining its integrity with security.</p>
                </div>
              </div>

               <div className="row gx-1">
                <div className="col border border-light-subtle rounded-3 p-4 m-1">
                  <h4 className="fw-normal text-center">No Need for Trusted Third Parties</h4>
                  <p>The integrity of documents is guaranteed to an Ethereum Blockchain, not forcing your business to depend on any third party.</p>
                </div>
              </div>

            </div>
        </main>

        <footer className="mt-auto text-white-50">
          <p>
            Built by{" "}
            <a href="https://p2ptech.dev/" className="text-white">
              P2PTechnology
            </a>
          </p>
        </footer>
      </div>
    </>
  );
}

export default Feature;
