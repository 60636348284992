import React from "react";
import "./App.css";
import { Link } from "react-router-dom";

function About() {
  return (
    <>
      <div className="cover-container d-flex w-100 h-100 p-3 mx-auto flex-column">
        <header className="mb-auto">
          <div>
            <h3 className="float-md-start mb-0" style={{color: "#a6e9d5"}}>CryptoSign</h3>
            <nav className="nav nav-masthead justify-content-center float-md-end">
              <Link to={`/`} className={"nav-link fw-bold py-1 px-0"}>Home</Link>
              <Link to={`/about`} className={"nav-link fw-bold py-1 px-0 active"}>About</Link>
              <Link to={`/features`} className={"nav-link fw-bold py-1 px-0"}>Features</Link>
            </nav>
          </div>
        </header>


        <main className="text-start">
          <h4 className="mb-3 text-center" style={{color: "#1aa179"}}>Blockchain-Powered Data Integrity Assurance Service</h4>
          
          <div className="container">
            <div className="row">
                <p>In today’s digital landscape, ensuring the integrity and authenticity of data is paramount, especially for industries where the accuracy of records is crucial. Our Blockchain-Powered Data Integrity Assurance Service offers a robust solution to safeguard your data without the need to store files directly.</p>

                <ul>
                  <li>Data Integrity Guarantee: Even without storing files, our service provides clients with the assurance that their data has not been tampered with since its generation. Leveraging blockchain technology, we register hashes of files, creating an immutable proof of data integrity over time. This is particularly valuable for organizations in sectors such as healthcare or finance, where maintaining the authenticity and integrity of records is paramount.</li>
                  <li>Proof of Existence and Temporality: By leveraging blockchain, we create a verifiable proof of the existence and temporality of files. Registering hashes on the blockchain establishes an immutable timestamp, proving the existence of files at a specific moment in time. This functionality proves invaluable in legal disputes, audits, and commercial transactions where establishing the chronological sequence of events is crucial.</li>
                  <li>Verification and Audit Services: Our service extends beyond mere data integrity assurance; we provide verification and audit services to empower clients to independently verify the integrity of their files at any time. Clients can compare the hashes stored on the blockchain with those of the files in their possession, providing peace of mind and reassurance in the integrity of their data.</li>
                </ul>

                <p>With our Blockchain-Powered Data Integrity Assurance Service, clients can rest assured that their data remains untampered with, backed by immutable proof of integrity and the ability to independently verify the accuracy of their records. Protect your data integrity with confidence, powered by blockchain technology.</p>
            </div>
          </div>
        </main>

        <footer className="mt-auto text-white-50">
          <p>
            Built by{" "}
            <a href="https://p2ptech.dev/" className="text-white">
              P2PTechnology
            </a>
          </p>
        </footer>
      </div>
    </>
  );
}

export default About;
